import "./src/scss/flaticon.scss"
import "./src/scss/uicons-regular-rounded.scss"
import "./src/scss/bradiance.scss"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `bradiance. ` + `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
